/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Box, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import TopBox from "./components/svgs/topBox.svg";
import Filterimage from "./components/svgs/Rectangle.png";
import { motion } from "framer-motion";

import axios from "axios";
import Form from "./components/svgs/Form";

function App() {
  const isFirstRun = useRef(true);

  const [data, setData] = useState(null);
  const [image, setImage] = useState(null);
  const [load, setLoad] = useState(false);
  const [ready, setReady] = useState(false);

  const windowSize = useMemo(
    () => ({
      width: window.innerWidth,
      height: window.innerHeight,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.innerWidth, window.innerHeight]
  );

  const bigScreen = useMemo(
    () => window.innerWidth > 1600,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.innerWidth]
  );

  const getImage = async (mainImage, key) => {
    await axios
      .get(`${mainImage}?cors`, { responseType: "blob" })
      .then((response) => {
        setImage((prev) => ({
          ...prev,
          [key]: URL.createObjectURL(response.data),
        }));
      });
  };

  const getData = async () => {
    isFirstRun.current = false;
    console.log({ referrer: document.referrer });
    await axios
      .get(
        "https://client.orkabit.com/api/v1/page?route=/&domain=issamnahhas.com"
      )
      .then(async (response) => {
        setData(response.data.page_data);
        if (windowSize.width < 786) {
          await getImage(
            response.data.page_data.mainImage.mobile._image,
            "mobile"
          );
        } else {
          await getImage(
            response.data.page_data.mainImage.desktop._image,
            "desktop"
          );
        }
        return response;
      })
      .then(async (response) => {
        setReady(true);

        setTimeout(() => {
          setLoad(true);
        }, 1000);

        if (windowSize.width < 786) {
          await getImage(
            response.data.page_data.mainImage.desktop._image,
            "desktop"
          );
        } else {
          await getImage(
            response.data.page_data.mainImage.mobile._image,
            "mobile"
          );
        }
      });
  };

  useEffect(() => {
    if (!isFirstRun.current) return;
    getData();
    // eslint-disable-next-line
  }, []);

  const sizes = (size) => {
    if (windowSize.width === 0) return;
    if (!bigScreen) return `${size}px`;
    else return `${(windowSize.width / 1920) * size}px`;
  };

  return ready ? (
    <Flex
      w="100%"
      alignItems="center"
      justifyContent="center"
      bg="#FFFFFF"
      position="relative"
      minH="600px"
      overflowX="hidden"
      flexDirection={{ base: "column", md: "row" }}
      h={{
        base: "-webkit-fill-available",
        lg: "100vh",
      }}
      flex={1}
    >
      <Box
        position="absolute"
        className={windowSize.width > 786 ? "img-box" : "img-box-mobile"}
        bgImage={
          windowSize.width < 786
            ? `url(${image?.mobile})`
            : `url(${image?.desktop})`
        }
        w={{
          base: "100%",
          md: `${windowSize.width / 3.7428571}px`,

          lg: `${windowSize.width / 2.7428571}px`,
        }}
        maxW="800px"
        h={{ base: "250px", md: "100%" }}
        zIndex="3"
        transition="all 1.3s "
        top={{ base: !load ? "-250px" : "0", md: "0" }}
        right={{
          base: "0",
          md: !load ? `-${windowSize.width / 3.7428571}px` : "0px",
          lg: !load ? `-${windowSize.width / 2.7428571}px` : "0px",
        }}
      >
        {" "}
        <Box
          w="100%"
          h="100%"
          mixBlendMode="color"
          opacity="0.9"
          zIndex="4"
          position="absolute"
          top="0"
          left="0"
          bgImage={`url(${Filterimage})`}
        />{" "}
      </Box>
      <Flex
        flex={1}
        flexShrink="0"
        zIndex="2"
        paddingRight={{
          base: "0",
          md: !load ? "0" : `${windowSize.width / 3.7428571 + 50}px`,
          lg: !load ? "0" : `${windowSize.width / 2.7428571 + 75}px`,
        }}
        paddingTop={{
          base: load ? "250px" : "0",
          md: "0",
        }}
        flexDirection="column"
        transition="all 1.3s "
        justifyContent="space-between"
        alignItems="center"
        w={{ base: "90%", md: "100%" }}
        h="100%"
        gap={{ base: "20px", md: "30px", lg: sizes(40) }}
        position="relative"
      >
        <Box
          flexShrink="0"
          w={{ base: "117px", md: "200px", xl: sizes(297) }}
          h={{ base: "120px", md: "220px", xl: sizes(327) }}
          bg="#AC936E"
          position="relative"
        >
          <Image
            w="50%"
            top="50%"
            left="50%"
            transform="translate(-50%)"
            src={TopBox}
            position="absolute"
          />
        </Box>

        <Flex
          justifyContent="center"
          alignItems="center"
          w="100%"
          flexDirection="column"
          color="#AC936E"
          gap={{ base: "0", md: "10px" }}
        >
          <Text
            marginBottom={{ base: "-10px", lg: "-30px" }}
            fontSize={{ base: "14px", md: "22px", xl: sizes(38) }}
          >
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.9, stiffness: 120 }}
            >
              website
            </motion.span>
          </Text>
          <Text
            fontSize={{
              base: "29px",
              md: "42px",
              xl: sizes(76),
            }}
            position="relative"
            fontWeight="bold"
          >
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.9, stiffness: 120 }}
            >
              Under Construction
            </motion.span>

            <Text
              fontWeight="bold"
              fontSize={{ base: "29px", md: "42px", xl: sizes(76) }}
              position="absolute"
              bottom={{ base: "-15px", md: "-25px" }}
              left="0%"
              w="100%"
              textAlign="center"
              filter={{ base: "blur(4px)", md: "blur(6px)" }}
              opacity="0.5"
            >
              <motion.span
                initial={{ opacity: 0, textTransform: "uppercase " }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.9, stiffness: 120 }}
              >
                Coming Soon
              </motion.span>
            </Text>
          </Text>
        </Flex>
        <Text
          // paddingTop="20px"
          maxW={{ base: "290px", md: "710px" }}
          lineHeight={{ base: "14px", md: "unset" }}
          textAlign="center"
          fontSize={{ base: "11px", md: "13px", lg: sizes(18) }}
          color="#AC936E"
        >
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.9, stiffness: 120 }}
          >
            {data?.message?.text}
          </motion.span>
        </Text>
        <Form windowSize={windowSize} />
        <Flex
          fontSize={{ base: "10px", md: "13px", lg: sizes(18) }}
          color="#AC936E"
          w="100%"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {data?.contactUs?.numbers?.map((e, i) => (
            <Box key={i}>
              <span>{e.country}: </span>
              <a href={`tel:${e.phoneNumber}`}>{e.phoneNumber}</a>
            </Box>
          ))}
          <Box>
            <a href={`mailto:${data?.contactUs?.email}`}>
              {data?.contactUs?.email}
            </a>
          </Box>
          {data?.contactUs?.socialMedia?.map((e, i) => (
            <Box key={i}>
              <span>{e.platform}: </span>
              <a href={e.link}>{e.pageName}</a>
            </Box>
          ))}
          <Flex
            justifyContent="center"
            alignItems="center"
            gap="10px"
            marginTop="20px"
            fontSize={{ base: "9px", md: "13px", lg: sizes(16) }}
            textTransform="uppercase"
            fontWeight="bold"
            marginBottom="20px"
          >
            <span style={{ flexShrink: "0" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.68"
                height="12.819"
                viewBox="0 0 7.68 9.819"
              >
                <path
                  id="Path_54"
                  data-name="Path 54"
                  d="M2.586-50v4.952H.477l3.84,4.867,3.84-4.867H6.178V-50Z"
                  transform="translate(-0.477 50)"
                  fill="#ac936e"
                />
              </svg>
            </span>
            <a
              href={`${data?.portifolio?._pdfFile}?download&fileName=${data?.portifolio?.fileName}`}
              download
            >
              Download Company Profile
            </a>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : (
    <Flex
      flex={1}
      h={{
        base: "-webkit-fill-available",
        lg: "100vh",
      }}
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner
        color="#AC936E"
        size={{
          lg: "lg",
          base: "md",
        }}
      />
    </Flex>
  );
}

export default App;
