import React from "react";
import { Input } from "@chakra-ui/input";
import { Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";
import axios from "axios";

const Form = ({ windowSize }) => {
  const [message, setMessage] = React.useState({
    email: "",
  });
  const [sucsses, setSucsses] = React.useState(false);
  const [err, setErr] = React.useState("");

  const submit = (e) => {
    const newMessges = { ...message };
    newMessges[e.target.id] = e.target.value;
    setMessage(newMessges);
  };

  const submitMessage = async (e) => {
    e.preventDefault();

    await axios
      .post(
        "https://client.orkabit.com/api/v1/contact-us?domainName=issamnahhas.com",
        {
          email: message.email,
        }
      )
      .then((res) => {
        if (!res) return;
        wordPlay();
        setTimeout(() => {
          setMessage({ email: "" });
        }, 3000);
      })
      .catch((err) => {
        err.response.data.map((msg) => setErr(msg.message));
        setTimeout(() => {
          setErr("");
        }, 5000);
      });
  };

  const wordPlay = () => {
    setSucsses(true);
    setTimeout(() => {
      setSucsses(false);
    }, 5000);
  };

  return (
    <Flex
      w={{ base: "80%", md: "100%" }}
      // marginTop={{ base: "-20px", md: "-10px", lg: "-20px" }}
      h={{ base: "30px", md: "45px ", xl: "60px" }}
      zIndex=" 4"
      flexShrink="0"
    >
      <motion.form
        initial={{
          width: "100%",
          display: "flex",
          height: "100%",
          flexDirection: "row",
          gap: windowSize.width < 768 ? "4px" : "10px",
          justifyContent: "center",
          position: "relative",
          alignItems: "center",

          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
        }}
        transition={{ duration: 1 }}
      >
        <Input
          id="email"
          onChange={(e) => submit(e)}
          value={message.email}
          fontSize={{ base: "10px", md: "14px", lg: "18px" }}
          required
          maxW="420px"
          w="100%"
          borderRadius="20px"
          border="1px solid #AC936E"
          color="#AC936E"
          padding="0 20px"
          h="100%"
          placeholder="Your mail"
        />
        <Flex
          justifyContent="center"
          className="send-button"
          boxShadow=" 0px 3px 6px  0px rgba(0,0,0,0.2)"
          alignItems="center"
          borderRadius="20px"
          minW={{ base: "unset", md: "100px", lg: "130px" }}
          padding={{ base: "2px 15px", md: "0 5px" }}
          cursor="pointer"
          h="100%"
          flexShrink="0"
          bg={err.length === 0 ? ` #AC936E` : "red.500"}
          color="#fff"
          onClick={submitMessage}
          fontSize={{ base: "9px", md: "16px", lg: "20px", xl: "17px" }}
        >
          <>
            {sucsses && err.length === 0 ? (
              "Thank you"
            ) : err.length !== 0 ? (
              err
            ) : (
              <>Notify me</>
            )}
          </>
        </Flex>
      </motion.form>
    </Flex>
  );
};

export default Form;
